<template>
	<div>
		<div class="box">
			<div class="navbox">
				<div
					style="color: #ba1c2b;font-size: 32px;margin-top: 20px;font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;font-weight: 400;display: flex;align-items: center;">
					<img style="width: 171rpx; height: 53px" src="../assets/pas.png" alt="" />
					<!-- <div style=""> 中保知行</div> -->
				</div>
				<div class="nav fcs">
					<div :class="'item fcc ' + (path == '/' ? 'trages' : '')" @click="changenav('/', 1)">
						<div>首页</div>
					</div>
					<div @mouseleave="mouseleaves">
						<div style=" position: relative"
							:class="'item fcc ' +(tablist.indexOf('/heatsearch') == -1 ? '' : 'trages')"
							@mouseenter="mouseenters" @click="changenav('/heatsearch', 2)">
							<div>产品服务</div>
						</div>
						<div style="position: absolute; top: 60px; z-index: 998" v-if="tags">
							<div class="ssa" v-for="(item, index) in boduobj.product_cate" :key="index"
								@click="changes(item.id)" style="">
								{{ item.product_cate }}
							</div>
						</div>
					</div>
					<div @mouseleave="mouseleaves2">
						<div :class="'item fcc ' +(tablist.indexOf('/supervise') == -1 ? '' : 'trages')"
							style="position: relative" class="item fcc" @mouseenter="mouseenters2"
							@click="changenav('/supervise', 4)">
							<div>企业资讯</div>
						</div>
						<div style="position: absolute; top: 60px; z-index: 998" v-if="tags2">
							<div class="ssa" v-for="(item, index) in tanlists" :key="index" @click="changes2(item.id)"
								style="">
								{{ item.news_cate }}
							</div>
						</div>
					</div>
					<div>
						<div :class="'item fcc ' +(tablist.indexOf('/about_us') == -1 ? '' : 'trages')"
							style="position: relative" class="item fcc" @click="changenav('/about_us', 3)">
							<div>企业案例</div>
						</div>
						<div style="position: absolute; top: 60px; z-index: 998" v-if="tags1">
							<div class="ssa" v-for="(item, index) in boduobj.cases" :key="index"
								@click="changes1(item.cate_id)" style="">
								{{ item.cate_name }}
							</div>
						</div>
					</div>
					<div class="item fcc font-size: 20px;" @click="tiaozhuan">
						<div>知行学堂</div>
					</div>
					<div :class="'item fcc ' + (tablist.indexOf('/study') == -1 ? '' : 'trages')" class="item fcc;"
						@click="changenav('/study', 5)">
						<div>联系我们</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Header",
		props: {},
		data() {
			return {
				path: "/",
				number: 0,
				tabindex: 0,
				tablist: [],
				tags: false,
				tags1: false,
				tags2: false,
				boduobj: [],
				tanlists: [],
			};
		},
		created() {
			this.lists();
			this.tanlist();
			// this.changeroute();
		},
		watch: {
			$route(to, from) {
				this.changeroute();
			},
		},
		methods: {
			tanlist() {
				this.$https.get("/api/index/xinwen").then((res) => {
					// res.data.about.image=this.imgurl+res.data.about.image
					// 产品分类

					this.tanlists = res.data;
					console.log(this.tanlists, "  console.log(res);");
				});
			},
			lists() {
				this.$https.get("/api/index").then((res) => {
					// res.data.about.image=this.imgurl+res.data.about.image
					// 产品分类
					console.log(res);
					this.boduobj = res.data;
				});
			},
			mouseenters() {
				this.tags = true;
			},
			mouseleaves() {
				this.tags = false;
			},
			mouseenters1() {
				this.tags1 = true;
			},
			mouseleaves1() {
				this.tags1 = false;
			},
			mouseenters2() {
				this.tags2 = true;
			},
			mouseleaves2() {
				this.tags2 = false;
			},
			tiaozhuan() {
				window.open('https://study.zhixingtec.cn', '_blank');
			},
			changes(id) {
				//  event.stopPropagation();
				//  event.preventDefault()
				sessionStorage.setItem("pagepath", "/heatsearch");
				console.log(22222);
				this.$router.push({
					path: "/dangjian",
					query: {
						lei: id
					},
				});

				// return false
			},
			changes1(id) {
				//  event.stopPropagation();
				//  event.preventDefault()
				sessionStorage.setItem("pagepath", "/about_us");
				console.log(22222);
				this.$router.push({
					path: "/about_us",
					query: {
						leid: id
					},
				});

				// return false
			},
			changes2(id) {
				//  event.stopPropagation();
				//  event.preventDefault()
				sessionStorage.setItem("pagepath", "/supervise");
				console.log(22222);
				this.$router.push({
					path: "/supervise",
					query: {
						lei: id
					},
				});

				// return false
			},
			changenav(path, index) {
				// if(index==2){
				//   this.tags=true
				// }
				this.tablist = [];
				this.tabindex = index;
				this.tablist.push(path);
				console.log("123132");
				this.path = path;
				this.$router.push({
					path: path,
				});
				sessionStorage.setItem("pagepath", path);
			},
			changeroute() {
				//  this.tablist=[];
				let pagepath = sessionStorage.getItem("pagepath");
				console.log(pagepath, "pagepath");
				if (pagepath) {
					this.path = pagepath;
					this.tablist = pagepath;
					//  console.log(pagepath,22121);
				} else {
					// this.path = "/";
					// this.tablist.push("/");
				}
			},
		},
	};
</script>

<style scoped>
	.ssa:hover {
		background: #ba1c2b;
		cursor: pointer;
		z-index: 9999;
	}

	.ssa {
		width: 100px;
		text-align: center;
		padding: 8px 18px;
		font-size: 16px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		background: #e40111;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
	}

	.trages {
		background-color: #ba1c2b;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		color: #fff;
	}

	.box {
		width: 100%;
	}

	.logobox {
		width: 1200px;
		height: 90px;
		margin: 0 auto;
		font-size: 20px;
		cursor: pointer;
	}

	.logobox .logo {
		width: 164px;
		height: 50px;
	}

	.logobox .searchbox {
		width: 150px;
		height: 50px;
		background: #ffffff;
		border-radius: 25px;
		border: 1px solid #999999;
		font-size: 20px;
	}

	.logobox .searchbox img {
		width: 29px;
		height: 29px;
		margin-right: 10px;
	}

	.logobox .app img {
		width: 20px;
		height: 28px;
		margin-right: 10px;
	}

	.logobox .btn {
		width: 95px;
		height: 40px;
		background: #0049c2;
		border-radius: 10px;
		color: #ffffff;
		margin: 0 30px;
	}

	.logobox .login {
		width: 110px;
		height: 50px;
		color: #fdc10b;
	}

	.navbox {
		width: 1200px;
		height: 100px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.navbox .nav {
		height: 60px;
		font-size: 18px;
	}

	.navbox .nav .item {
		width: 100px;
		height: 60px;
		text-align: center;
		cursor: pointer;
	}
</style>