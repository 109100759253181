import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import http from './request/api.js'
import https from './request/http.js'

import Footer from '@/components/Footer'
import Header from '@/components/Header'
Vue.config.productionTip = false
Vue.prototype.$api = http;
Vue.prototype.$https = https;
Vue.use(ElementUI)
Vue.component('Header', Header)
Vue.component('Footer', Footer)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')