/**
 * 数据请求的唯一入口
 */
import home from './modules/home.js';


export default {
    // baseUrl: "http://zhongbaozhihang.wjruanjian.com",
    baseUrl: "https://www.zhixingtec.cn/",
    home,


}