import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: () =>
        import ('../views/Home.vue')
}, {
    path: '/heatsearch',
    name: 'Heatsearch',
    component: () =>
        import ('../views/Heatsearch.vue')
}, {
    path: '/focalpoint',
    name: 'Focalpoint',
    component: () =>
        import ('../views/Focalpoint.vue')
}, {
    path: '/supervise',
    name: 'Supervise',
    component: () =>
        import ('../views/Supervise.vue')
}, {
    path: '/study',
    name: 'Study',
    component: () =>
        import ('../views/Study.vue')
}, {
    path: '/dangjian',
    name: 'Dangjian',
    component: () =>
        import ('../views/Dangjian.vue')
}, {
    path: '/life',
    name: 'Life',
    component: () =>
        import ('../views/Life.vue')
}, {
    path: '/about_us',
    name: 'About_us',
    component: () =>
        import ('../views/About_us.vue')
}, {
    path: '/contact',
    name: 'Contact',
    component: () =>
        import ('../views/Contact.vue')
}, {
    path: '/uploadnews',
    name: 'Uploadnews',
    component: () =>
        import ('../views/Uploadnews.vue')
}, {
    path: '/my',
    name: 'my',
    component: () =>
        import ('../views/my.vue')
}]

const router = new VueRouter({
        // mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
    // router.beforeEach((to, from, next) => {
    //     if (to.path != '/') {
    //         document.body.scrollTop = 0

//         document.documentElement.scrollTop = 0
//     } else {
//         document.body.scrollTop = 0

//         document.documentElement.scrollTop = 0
//     }
//     next()
// })
export default router