<template>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    "
  >
    <el-carousel
      indicator-position="outside"
      style="width: 100%"
      :height="imgHeight + 'px'"
    >
      <el-carousel-item v-for="item in bander" :key="item">
        <img class="banner" style="width: 100%;height:700px;" :src="item" alt="" />
      </el-carousel-item>
    </el-carousel>

    <div style="width: 1200px">
      <div
        style="
          display: flex;
          margin: 0 20px;
          margin-top: 104px;
          margin-bottom: 70px;
          align-items: center;
          justify-content: center;
        "
      >
        <img
          class="banner"
          style="width: 34px; height: 12px"
          src="../assets/left.png"
          alt=""
        />
        <div
          style="
            font-size: 34px;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #333333;
            margin: 0 20px;
          "
        >
          关于我们 | ABOUT US
        </div>
        <img
          class="banner"
          style="width: 34px; height: 12px"
          src="../assets/right.png"
          alt=""
        />
      </div>
      <div style="height: 360px; display: flex; align-items: center">
        <div
          style="
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          "
        >
          <div v-html="boduobj.about.about_blurb"></div>
          <div
            @click="my(boduobj.about.id)"
            style="display: flex; justify-content: flex-end; margin-top: 10px"
          >
            <img
              style="width: 24px; height: 24px"
              src="../assets/hou2.png"
              alt=""
            />
          </div>
        </div>
        <img
          style="width: 360px; height: 360px; margin-left: 40px"
          :src="boduobj.about.image"
          alt=""
        />
      </div>
      <!-- 产品服务 | PRODUCTS -->
      <div
        style="
          display: flex;
          margin: 0 20px;
          margin-top: 104px;
          margin-bottom: 70px;
          align-items: center;
          justify-content: center;
        "
      >
        <img
          class="banner"
          style="width: 34px; height: 12px"
          src="../assets/left.png"
          alt=""
        />
        <div
          style="
            font-size: 34px;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #333333;
            margin: 0 20px;
          "
        >
          产品服务 | PRODUCTS
        </div>
        <img
          class="banner"
          style="width: 34px; height: 12px"
          src="../assets/right.png"
          alt=""
        />
      </div>
      <div style="display: flex">
        <div
          @click="trage(item.name, item.id)"
          :class="
            'trage ' + (chooseList.indexOf(item.name) == -1 ? '' : 'trages')
          "
          v-for="(item, index) in list"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        style="display: flex; align-items: center"
        v-if="productlists.length > 0"
      >
        <img
          @click="productindex -= 1"
          style="width: 34px; height: 34px"
          src="../assets/qian.png"
          alt=""
        />
        <div style="margin-left: 60px; margin-right: 20px">
          <div
            style="
              font-size: 25px;
              font-family: PingFang SC-Heavy, PingFang SC;
              font-weight: 800;
              color: #333333;
            "
          >
            {{ productlists[productindex].product_name }}
          </div>
          <div style="margin: 20px 0" class="s1">
            {{ productlists[productindex].product_blurb }}
          </div>

          <div
            @click="uploadnews(productlists[productindex].id)"
            style="
              width: 91px;
              height: 33px;
              border-radius: 8px 8px 8px 8px;
              opacity: 1;
              border: 1px solid #ba1c2b;
              font-size: 14px;
              font-family: PingFang SC-Heavy, PingFang SC;
              font-weight: 800;
              color: #ba1c2b;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 20px;
            "
          >
            查看详情
          </div>
        </div>
        <img
          style="width: 320px; height: 198px; margin-right: 56px"
          :src="productlists[productindex].image"
          alt=""
        />
        <img
          @click="productindex += 1"
          style="width: 34px; height: 34px"
          src="../assets/hou2.png"
          alt=""
        />
      </div>
      <div v-else style="text-align: center">暂无数据</div>
      <!-- 新闻动态 | LATEST NEWS -->
      <div
        style="
          display: flex;
          margin: 0 20px;
          margin-top: 104px;
          margin-bottom: 70px;
          align-items: center;
          justify-content: center;
        "
      >
        <img
          class="banner"
          style="width: 34px; height: 12px"
          src="../assets/left.png"
          alt=""
        />
        <div
          style="
            font-size: 34px;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #333333;
            margin: 0 20px;
          "
        >
          企业资讯 | LATEST NEWS
        </div>
        <img
          class="banner"
          style="width: 34px; height: 12px"
          src="../assets/right.png"
          alt=""
        />
      </div>
      <div style="display: flex; align-items: center">
        <div style="margin-right: 40px">
          <div
            @click="trage1(item.name, item.id)"
            :class="
              'trage1 ' + (chooseList1.indexOf(item.name) == -1 ? '' : 'trages')
            "
            v-for="(item, index) in list1"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
        <div
          style="
            display: flex;

            justify-content: space-between;
            flex-wrap: wrap;
          "
        >
          <div
            @click="life(item.id)"
            v-for="(item, index) in news_list"
            :key="index"
            style="
              width: 480px;
              height: 184px;
              background: #ffffff;
              border-radius: 12px 12px 12px 12px;
              opacity: 1;
              border: 1px solid #cccccc;
              padding: 25px 20px;
              display: flex;
              margin-bottom: 30px;
            "
          >
            <div>
              <div
                style="
                  font-size: 29px;

                  font-weight: 800;
                  color: #333333;
                "
              >
                {{ item.time }}
              </div>
              <div
                style="
                  margin-top: 30px;
                  width: 84px;
                  height: 0px;
                  opacity: 1;
                  border: 1px solid #999999;
                "
              ></div>
            </div>
            <div style="margin-left: 40px">
              <div>
                <div
                  class="s2"
                  style="font-size: 18px; font-weight: 800; color: #333333"
                >
                {{item.title}}
                </div>
                <!--   v-html="item.content" -->
                <div
                
                  style="margin: 20px 0"
                  class="s11"
                >  {{ item.news_blurb }}</div>
                <div>
                  <img
                    style="width: 25px; height: 18px"
                    src="../assets/rig.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 为什么选择我们 | WHY CHOOSE US -->
      <div style="margin: 0 20px; margin-top: 104px; margin-bottom: 70px">
        <div style="display: flex; align-items: center">
          <img
            class="banner"
            style="width: 34px; height: 12px"
            src="../assets/left.png"
            alt=""
          />
          <div
            style="
              font-size: 34px;

              font-weight: 800;
              color: #333333;
              margin: 0 20px;
            "
          >
            WHY CHOOSE US
          </div>
        </div>
        <div style="display: flex; align-items: center; margin-left: 20px">
          <div
            style="
              font-size: 34px;

              font-weight: 800;
              color: #333333;
              margin: 0 20px;
            "
          >
            为什么选择我们
          </div>
          <img
            class="banner"
            style="width: 34px; height: 12px"
            src="../assets/right.png"
            alt=""
          />
        </div>
      </div>
      <div style="display: flex">
        <div>
          <div>
            <div
              style=""
              v-for="(item, index) in boduobj.choose[0]"
              :key="index"
            >
              <div class="s2" style="display: flex; align-items: center">
                <img
                  style="width: 25px; height: 18px; margin-right: 20px"
                  src="../assets/rig.png"
                  alt=""
                />
                <div class="s2" style="color: #ba1c2b; font-size: 18px">
                  {{ item.key }}
                </div>
              </div>
              <div style="margin: 16px 0 32px 0; font-size: 14px" class="s3">
                {{ item.val }}
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            margin-left: 80px;
            display: flex;
            flex-wrap: wrap;
            margin-top: 30px;
          "
        >
          <div
            v-for="(item, index) in boduobj.choose_you[0]"
            :key="index"
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 60px;
            "
          >
            <div
              style="
                width: 150px;
                height: 50px;
                font-size: 41px;

                font-weight: 500;
                color: #ba1c2b;
              "
            >
              {{ item.val }}
            </div>
            <div
              style="
                width: 150px;
                font-size: 19px;

                font-weight: bold;
                color: #333333;
                text-align: center;
              "
            >
              {{ item.key }}
            </div>
          </div>
        </div>
      </div>
      <!-- 用户案例 | CASES -->
      <div
        style="
          display: flex;
          margin: 0 20px;
          margin-top: 104px;
          margin-bottom: 70px;
          align-items: center;
          justify-content: center;
        "
      >
        <img
          class="banner"
          style="width: 34px; height: 12px"
          src="../assets/left.png"
          alt=""
        />
        <div
          style="
            font-size: 34px;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #333333;
            margin: 0 20px;
          "
        >
          企业案例| CASES
        </div>
        <img
          class="banner"
          style="width: 34px; height: 12px"
          src="../assets/right.png"
          alt=""
        />
      </div>
      <div style="display: flex">
        <div
          v-for="(item, index) in tablist"
          :key="index"
          @click="deatil(item.id)"
           style="margin-right: 10px;"
        >
          <!-- <div
            :class="'trage2 ' + (chooseList.indexOf(item.name) == -1 ? '' : '')"
          >
            {{ item.name }}
          </div> -->
          <img
            style="width: 290px; height: 206px; vertical-align: bottom; border: 1px solid #cccccc;"
            :src="item.image"
            alt=""
          />
          <div
            style="
            
              width: 290px;
              padding:5px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              opacity: 1;
              
              border: 1px solid #cccccc;
            "
          >
            {{ item.text }}
          </div>
        </div>
      </div>
      <!-- 查看更多案例 -->
      <div
        @click="les()"
        style="
          width: 165px;
          height: 58px;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          border: 1px solid #999999;
          margin: 0 auto;
          margin-top: 62px;
          margin-bottom: 74px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        查看更多
      </div>
    </div>
  </div>
</template>

<script>
import url from "../request/api";
export default {
  data() {
    return {
      imgurl: url.baseUrl,
      boduobj: {},
      imgHeight: "",
      // 产品分类
      list: [
        // { name: "教学基础 设施建设" },
        // { name: "校园信息化和教学信息化" },
        // { name: "教育教学咨询和课程开发服务" },
        // { name: "产业学院 专业共建" },
      ],
      // 产品数据
      productlists: [],
      productindex: 0,
      chooseList: [],
      list1: [
        // { name: "公司动态" }, { name: "产品动态" }, { name: "新闻中心" }
      ],
      news_list: [],
      chooseList1: [],
      tablist: [
        // {
        //   name: "教学基础 设施建设",
        //   src: "../assets/pic2.png",
        //   text: "安徽亳州中药科技学校",
        // },
        // {
        //   name: "校园信息化和教学信息化",
        //   src: "../assets/pic2.png",
        //   text: "安徽亳州中药科技学校",
        // },
        // {
        //   name: "教育教学咨询和课程开发服务",
        //   src: "../assets/pic2.png",
        //   text: "安徽亳州中药科技学校",
        // },
        // {
        //   name: "产业学院 专业共建",
        //   src: "../assets/pic2.png",
        //   text: "安徽亳州中药科技学校",
        // },
      ],
      list2: [],
      list3: [],
      bander: [],
    };
  },
  mounted() {
    this.get_banner();
    // 产品分类
    // this.productlist()
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener(
      "resize",
      () => {
        this.get_banner();
      },
      false
    );
  },
  created() {
    this.lists();
    this.banderlist();
  },
  methods: {
    les() {
      console.log(121212);
      sessionStorage.setItem("pagepath", "/about_us");
      this.$router.push({
        path: "/about_us",
      });
    },
    banderlist() {
      this.$https.get(`/api/index/banner?name=index_banner`).then((res) => {
        console.log(res);
        // res.data.forEach((element,index) => {
        //         res.data[index] = this.imgurl+element
        //       });
        this.bander = res.data;
      });
    },
    // 用胡安例
    deatil(id) {
      sessionStorage.setItem("pagepath", "/focalpoint");
      this.$router.push({
        path: "/contact",
        query: { detailsid: id },
      });
    },
    lists() {
      this.$https.get("/api/index").then((res) => {
        // res.data.about.image=this.imgurl+res.data.about.image
        // 产品分类
        console.log(res);

        res.data.product_cate.forEach((element) => {
          element.name = element.product_cate;
        });
        this.chooseList.push(res.data.product_cate[0].product_cate);
        this.productlist(res.data.product_cate[0].id);
        this.list = res.data.product_cate;

        // 新闻\

        res.data.news_cate.forEach((i) => {
          i.name = i.news_cate;
        });
        this.news_lists(res.data.news_cate[0].id);
        this.chooseList1.push(res.data.news_cate[0].news_cate);
        this.list1 = res.data.news_cate;
        res.data.cases.forEach((j) => {
          j.name = j.cate_name;
          j.text = j.case_name;
        });
        this.tablist = res.data.cases;
        this.boduobj = res.data;
        this.list2 = res.data.choose;
        this.list3 = res.data.choose_you;
        console.log(this.list2, " this.list2");
        console.log(this.list1, "imgurl");
      });
    },
    // 产品分类
    productlist(id) {
      console.log(id, "id");
      this.$https
        .get(`/api/index/product_list?cate_id=${id}`, { cate_id: id })
        .then((res) => {
          // res.data.about.image=this.imgurl+res.data.about.image
          console.log(res, "/api/index/product_list");

          this.productlists = res.data;
        });
    },
    //新闻
    news_lists(id) {
      this.$https
        .get(`/api/index/news_list?cate_id=${id}`, { cate_id: id })
        .then((res) => {
          // res.data.about.image=this.imgurl+res.data.about.image
          console.log(res);
          this.news_list = res.data;
        });
    },
    my(id) {
      this.$router.push({
        path: "/my",
        query: { detailsid: id },
      });
    },
    uploadnews(id) {
      sessionStorage.setItem("pagepath", "/heatsearch");
      this.$router.push({
        path: "/uploadnews",
        query: { detailsid: id },
      });
    },
    life(id) {
      sessionStorage.setItem("pagepath", "/supervise");
      this.$router.push({
        path: "/life",
        query: {detailsid: id,path:'/',name:'企业资讯' },
      });
    },
    get_banner() {
      //获取到图片数据之后
      this.$nextTick(function () {
        // 获取窗口宽度*图片的比例，定义页面初始的轮播图高度 width=1920,height=480 图片的默认宽高
        this.imgHeight = (document.body.clientWidth * 700) / 1920;
        console.log(this.imgHeight, 12121);
      });
    },

    trage(item, id) {
      this.chooseList = [];
      this.productindex = 0;
      if (this.chooseList.indexOf(item) == -1) {
        this.chooseList.push(item);
        this.productlist(id);
      } else {
        this.chooseList.splice(this.chooseList.indexOf(item), 1);
      }
    },
    trage1(item, id) {
      this.chooseList1 = [];
      if (this.chooseList.indexOf(item) == -1) {
        this.chooseList1.push(item);
        this.news_lists(id);
      } else {
        this.chooseList1.splice(this.chooseList.indexOf(item), 1);
      }
    },
  },
  destroyed() {
    //切换页面后销毁监听窗口事件
    window.removeEventListener("resize", this.get_banner, false);
  },
};
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.trage1 {
  width: 155px;
  height: 58px;
  /* box-shadow: 4px 4px 10px 1px rgba(186, 28, 43, 0.2); */
  border-radius: 52px 52px 52px 52px;
  opacity: 1;
  border: 1px solid #ba1c2b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 83px;
  color: #ba1c2b;
}
.trage2 {
  width: 290px;
  height: 58px;
  /* border-radius: 8px 8px 8px 8px; */
  opacity: 1;
  border: 1px solid #ba1c2b;
  font-size: 18px;

  font-weight: bold;
  color: #ba1c2b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 28px;
  margin-bottom: 20px;
}
.trage {
  width: 280px;
  height: 68px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #ba1c2b;
  font-size: 18px;
  /* font-family: PingFang SC-Bold, PingFang SC; */
  font-weight: bold;
  color: #ba1c2b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 28px;
  margin-bottom: 80px;
  text-align: center;
}
.trages {
  background: #ba1c2b;
  color: #fff;
}
.s1 {
  width: 668px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.s11 {
  width: 297px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.s3 {
  width: 479px;
  padding: 0 0 0 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
}
.s2 {
  width: 297px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
