import http from '../http.js';
// 新闻列表
function newslist(params) {
	return http.get('/index/newslist', {
		params
	});
}
// 新闻详情
function newsdetails(params) {
	return http.get('/index/newsshow', {
		params
	});
}
// 图片展示列表
function imageslist(params) {
	return http.get('/index/imageslist', {
		params
	});
}
// 图片链接列表
function linkslist(params) {
	return http.get('/index/links', {
		params
	});
}
// 党建新闻列表
function speciallist(params) {
	return http.get('/index/speciallist', {
		params
	});
}
// 党建新闻详情
function specialdetails(params) {
	return http.get('/index/specialshow', {
		params
	});
}
// 联系我们-提交留言
function message(params) {
	return http.post('/index/message', params);
}
// 联系我们-信息
function lxwm(params) {
	return http.get('/index/lxwm', {
		params
	});
}
// 底部参数
function foot(params) {
	return http.get('/index/foot', {
		params
	});
}
// 学校简介
function about(params) {
	return http.get('/index/about', {
		params
	});
}
// 学校领导
function alumnuslist(params) {
	return http.get('/index/alumnuslist', {
		params
	});
}
// 轮播图
function banner(params) {
	return http.get('/index/index', {
		params
	});
}
// 提交报名信息
function apply(params) {
	return http.post('/user/apply', params);
}

export default {
	newslist,
	newsdetails,
	imageslist,
	linkslist,
	speciallist,
	specialdetails,
	message,
	lxwm,
	foot,
	about,
	alumnuslist,
	banner,
	apply
}