<template>
  <div>
    <div class="box fcc">
      <div class="con fc">
        <!-- <img class="logo" src="../assets/logo2.png" alt=""> -->
        <div class="nav">
          <div class="item s1" style="margin-bottom: 17px">产品服务</div>
          <div
            class="item s2"
            @click="changes('/dangjian',item.id)"
            v-for="(item, index) in bodylist.product_cate"
            :key="index"
            v-if="index<5"
          >
            {{ item.product_cate }}
          </div>
          <!-- <div class="item s2">产业学院 专业共建</div>
					<div class="item s2">校园信息化和教学信息化</div>
					<div class="item s2">教育教学咨询和课程开发服务</div> -->
        </div>
        <!-- <div class="nav">
          <div class="item s1" style="margin-bottom: 17px">职教政策</div>
          <div
		    @click="changes1('/about_us',item.id)"
            class="item s2"
            v-for="(item, index) in bodylist.product_cate"
            :key="index"
          >
            {{ item.product_cate }}
          </div>
        </div> -->
        <div class="nav"  style="margin-bottom: 30px">
          <div class="item s1">企业资讯</div>
          <div
		    @click="changes('/supervise',item.id)"
            class="item s2"
            v-for="(item, index) in bodylist.news_cate"
            :key="index"
          >
            {{ item.news_cate }}
          </div>
        </div>
        <div class="nav">
          <div class="item s1" style="margin-bottom: 17px">联系我们</div>
          <div
            class="item s2"
            v-for="(item, index) in bodylist.cont"
            :key="index"
          >
            {{ item.name }}{{ item.val }}
          </div>
        </div>
      </div>
    </div>

    <div class="beian fcc">
		{{bodylist.beian}}
      <!-- Copyright 2018中保知行 Inc.All rights reserved.
      京ICP备15000000号-1技术支持：中保知行 -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {},
  data() {
    return {
      info: [],
      bodylist: [],
	 
    };
  },
  created() {
    this.leblist();
  },
  methods: {
    changes(path, id) {
      console.log(22222);
      this.$router.push({
        path: path,
        query: { lei: id },
      });
    },
	 changes1(path, id) {
      console.log(22222);
      this.$router.push({
        path: path,
        query: { leid: id },
      });
    },
    leblist() {
      this.$https.get(`/api/index/daohang`).then((res) => {
        // res.data.forEach(element => {
        //   element.images=this.imgurl+ element.images
        //   element.casess.forEach(i=>{
        //     i.images=this.imgurl+  i.image
        //   })
        // });
        this.bodylist = res.data;
        console.log(this.bodylist);
      });
    },
    changenav(path) {
      sessionStorage.setItem("pagepath", path);
      this.$router.push({
        path: path,
      });
    },
    gopage(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  height: 220px;

  background: #f5f5f5;
}

.box .con {
  width: 1200px;
}

.box .logo {
  width: 164px;
  height: 50px;
  margin-right: 260px;
}

.box .nav {
  width: 250px;
  font-size: 16px;
  color: #ffffff;
  margin-right: 100px;
}

.box .nav .item {
  width: 235px;
  margin: 12px 0;
}
.beian {
  width: 100%;
  height: 40px;
  background: #555555;
  font-size: 12px;
  color: #ffffff;
}
.s1 {
  font-size: 25px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
}
.s2 {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
}
.s2:hover{
	/* background: black; */
   cursor:pointer;
   color: #ba1c2b;
}
</style>